import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import handleError from '@/plugins/handle-error';
import { mdiArrowLeftBoldCircleOutline, mdiContentCopy } from '@mdi/js';
import { useRouter } from '@core/utils';

export default function usePaymentSettings() {
  const { router } = useRouter();
  const websiteId = router.currentRoute.params.websiteId;

  const medoroPaymentsActive = ref(false);
  const stripePaymentsActive = ref(false);

  const websitePaymentApiKeys = ref({
    publicKey: null,
  });

  const generatingRSAKeyPair = ref(false);
  const generatedPublicRSAKeyDialog = ref(false);
  const generatedPublicRSAKey = ref(null);

  watch([medoroPaymentsActive, stripePaymentsActive], () => {
    store.dispatch('websitePaymentSettings/saveWebsitePaymentSettings', {
      websiteId,
      paymentSettings: {
        medoroPaymentsActive: medoroPaymentsActive.value,
        stripePaymentsActive: stripePaymentsActive.value,
      },
    })
      .then(websitePaymentSettings => {
        websitePaymentApiKeys.value = websitePaymentSettings;
        medoroPaymentsActive.value = websitePaymentSettings.medoroPaymentsActive;
        stripePaymentsActive.value = websitePaymentSettings.stripePaymentsActive;
      })
      .catch((error) => {
        handleError(error);
      });
  });

  const generateApiKeys = () => {
    store.dispatch('websitePaymentSettings/generateApiKeys', websiteId)
      .then(websitePaymentSettings => {
        websitePaymentApiKeys.value = websitePaymentSettings;
        medoroPaymentsActive.value = websitePaymentSettings.medoroPaymentsActive;
        stripePaymentsActive.value = websitePaymentSettings.stripePaymentsActive;
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const reGenerateApiKeys = () => {
    Vue.$confirm({
      message: 'Are you sure you want to regenerate API key? All API implementations with old API key will not work anymore!',
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: confirm => {
        if (confirm) {
          generateApiKeys();
        }
      },
    });
  };

  const generateRSAKeys = () => {
    generatingRSAKeyPair.value = true;
    store.dispatch('websitePaymentSettings/generateRSAKeys', websiteId).then((response) => {
      generatedPublicRSAKey.value = response.data;
      generatedPublicRSAKeyDialog.value = true;
    }).catch((error) => {
      handleError(error);
    }).finally(() => generatingRSAKeyPair.value = false);
  };

  const rsaPublicKeyModalClosed = () => generatedPublicRSAKey.value = null;

  const paymentsActive = computed({
    get: () => {
      return !(!medoroPaymentsActive.value && !stripePaymentsActive.value);
    }
  });

  const apiKeysGenerated = computed({
    get: () => {
      return websitePaymentApiKeys.value.publicKey !== null && websitePaymentApiKeys.value.secretKey !== null ;
    }
  });

  store.dispatch('websitePaymentSettings/fetchWebsitePaymentSettingsByWebsiteId', websiteId)
    .then(websitePaymentSettings => {
      if (websitePaymentSettings) {
        websitePaymentApiKeys.value = websitePaymentSettings;
        medoroPaymentsActive.value = websitePaymentSettings.medoroPaymentsActive;
        stripePaymentsActive.value = websitePaymentSettings.stripePaymentsActive;
      }
    });

  return {
    websiteId,
    websitePaymentApiKeys,
    generateApiKeys,
    generateRSAKeys,
    reGenerateApiKeys,
    apiKeysGenerated,
    medoroPaymentsActive,
    stripePaymentsActive,
    paymentsActive,
    generatingRSAKeyPair,
    generatedPublicRSAKey,
    generatedPublicRSAKeyDialog,
    rsaPublicKeyModalClosed,
    icons: {
      mdiArrowLeftBoldCircleOutline,
      mdiContentCopy,
    }
  };
}
