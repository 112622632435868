import { ref } from '@vue/composition-api';
import store from '@/store';
import handleError from '@/plugins/handle-error';
import { useRouter } from '@core/utils';
import Vue from 'vue';

export default function useStripePaymentSettings() {
  const { router } = useRouter();
  const websiteId = router.currentRoute.params.websiteId;

  const stripeSettingData = ref({
    mid: null,
  });

  const submitStripeSettings = () => {
    store.dispatch('websiteStripeSetting/saveWebsiteStripeSetting', {
      websiteId,
      stripeSetting: stripeSettingData.value,
    }).then((response) => {
      stripeSettingData.value = response;
      Vue.notify({
        type: 'success',
        text: 'Stripe settings updated',
      });
    }).catch((error) => {
      handleError(error);
    });
  };

  store.dispatch('websiteStripeSetting/fetchWebsiteStripeSettingByWebsiteId', websiteId)
    .then(stripeSetting => {
      if (stripeSetting) {
        stripeSettingData.value = stripeSetting;
      }
    });

  return {
    stripeSettingData,
    submitStripeSettings,
  };
}
