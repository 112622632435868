<template>
  <div>
    <v-card class="mb-5">
      <v-card-title @click="$router.back()" class="align-start cursor-pointer">
        <div>
          <v-icon>{{ icons.mdiArrowLeftBoldCircleOutline }}</v-icon>
          <div class="d-inline-flex payment-settings-back-button-name">Back to merchant websites</div>
        </div>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Website Payment API Key
          </v-card-title>

          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" md="9">
                  <v-row>
                    <v-col cols="12">
                      <div class="d-inline-flex w-full">
                        <v-text-field
                          :disabled="true"
                          outlined
                          dense
                          :value="websitePaymentApiKeys.publicKey"
                          hide-details
                          label="Your API Key"
                        ></v-text-field>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="copyValue(websitePaymentApiKeys.publicKey)"
                              class="pl-3 pr-2"
                            >
                              {{ icons.mdiContentCopy }}
                            </v-icon>
                          </template>
                          <span>Copy</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn v-if="!apiKeysGenerated" :disabled="!paymentsActive" x-large block color="info" @click="generateApiKeys">Generate key</v-btn>
                  <v-btn v-else :disabled="!paymentsActive" x-large block color="warning" @click="reGenerateApiKeys">Regenerate key</v-btn>
                  <span v-if="!paymentsActive" class="text--secondary">You must activate at least one payment system to (re)generate API key.</span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="fill-height d-flex flex-column justify-space-between">
          <v-card-title>
            <div class="d-flex w-full justify-lg-space-between">
              <span>LPB</span>
              <v-switch
                v-model="medoroPaymentsActive"
                class="mt-0 pt-0"
                dense
                :false-value="false"
                :true-value="true"
                color="info"
                value="info"
                hide-details
              ></v-switch>
            </div>
          </v-card-title>

          <v-card-text>
            <v-alert color="warning" text class='mb-0'>
              <p class="text-sm mb-0">First, you need to generate RSA key pair. Click the button below, and wait for the modal window with the generated Public RSA key to appear. Copy the Public key and paste it to appropriate field at the LPB platform.</p>
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="!paymentsActive || !medoroPaymentsActive || generatingRSAKeyPair" :loading='generatingRSAKeyPair' color="primary" @click="generateRSAKeys">
              Generate RSA key pair
            </v-btn>
          </v-card-actions>

          <v-dialog v-model='generatedPublicRSAKeyDialog' max-width="680px"
            @click:outside="rsaPublicKeyModalClosed"
          >
            <v-card class="pa-sm-10 pa-3">
                <v-card-title>Public RSA Key</v-card-title>
              <v-card-text>
                <v-alert color="warning" text class='mb-0'>
                  <p class="text-sm mb-0">Copy this public key and paste it at the LPB account settings</p>
                </v-alert>

                <p class='text-sm mt-4' v-html='generatedPublicRSAKey'></p>
              </v-card-text>
              <v-card-actions class='justify-end'>
                <v-btn color="info" @click="copyValue(generatedPublicRSAKey)">
                  <v-icon class="pl-3 pr-2">
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span class='pr-3'>Copy</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-text>
            <v-alert color="warning" text>
              <p class="text-sm mb-0">After uploading a public key to LPB platform, save the information and download the <code>Gateway Key</code> from the LPB platform. Please upload downloaded Gateway Key to the field down below.</p>
            </v-alert>
            <v-form ref="medoroSettingsForm" v-model='medoroSettingsFormValid' class="multi-col-validation">
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    id="payment-settings-medoro-public-key"
                    v-model="medoroGatewayKey"
                    :rules="rsaKeyFileInputValidationRules"
                    outlined
                    dense
                    accept='.pem'
                    label="Gateway key"
                  ></v-file-input>
                </v-col>
                <v-col cols='12' class='py-0'>
                  <v-alert color="warning" text>
                    <p class="text-sm mb-0">Please paste MID (Merchant ID) and Key Index values from LPB platform to the fields down below. Remember,in case of changing MID or Key Index - the uploading of the new keys is required.</p>
                  </v-alert>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!medoroPaymentsActive"
                    v-model="medoroSettingsData.merchantId"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="MID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!medoroPaymentsActive"
                    v-model="medoroSettingsData.keyIndex"
                    :rules="[validators.required]"
                    outlined
                    dense
                    label="Key Index"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="medoroSettingsData.autoDeposit"
                    class="mt-0 pt-0"
                    dense
                    :false-value="false"
                    :true-value="true"
                    color="info"
                    value="info"
                    label="Auto Deposit"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="!medoroPaymentsActive" color="primary" @click="submitMedoroSettings">Save LPB settings</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" class="">
        <v-card class="fill-height d-flex flex-column justify-space-between">
          <v-card-title>
            <div class="d-flex w-full justify-lg-space-between">
              <span>Stripe</span>
              <v-switch
                v-model="stripePaymentsActive"
                class="mt-0 pt-0"
                dense
                :false-value="false"
                :true-value="true"
                color="info"
                value="info"
                hide-details
              ></v-switch>
            </div>
          </v-card-title>

          <v-card-text>
            <v-form ref="stripeSettingsForm" class="multi-col-validation">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!stripePaymentsActive"
                    v-model="stripeSettingData.merchantId"
                    outlined
                    dense
                    label="MID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!stripePaymentsActive"
                    v-model="stripeSettingData.fee"
                    outlined
                    dense
                    type="number"
                    label="Fee %"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn :disabled="!stripePaymentsActive" color="primary" @click="submitStripeSettings">Save stripe settings</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import usePaymentSettings from '@/views/shared/users/merchant/merchant-website-payment-settings/usePaymentSettings';
import useMedoroPaymentSettings from '@/views/shared/users/merchant/merchant-website-payment-settings/useMedoroPaymentSettings';
import useStripePaymentSettings from '@/views/shared/users/merchant/merchant-website-payment-settings/useStripePaymentSettings';
import { copyValue } from '@core/utils';
import { required } from '@core/utils/validation';

export default {
  setup() {
    const {
      websitePaymentApiKeys,
      generateApiKeys,
      generateRSAKeys,
      reGenerateApiKeys,
      apiKeysGenerated,
      medoroPaymentsActive,
      stripePaymentsActive,
      paymentsActive,
      generatingRSAKeyPair,
      generatedPublicRSAKey,
      generatedPublicRSAKeyDialog,
      rsaPublicKeyModalClosed,
      icons,
    } = usePaymentSettings();

    const {
      medoroSettingsData,
      medoroGatewayKey,
      submitMedoroSettings,
      medoroSettingsForm,
      medoroSettingsFormValid,
      rsaKeyFileInputValidationRules,
    } = useMedoroPaymentSettings();

    const {
      stripeSettingData,
      submitStripeSettings,
    } = useStripePaymentSettings();

    return {
      copyValue,
      websitePaymentApiKeys,
      generateApiKeys,
      reGenerateApiKeys,
      apiKeysGenerated,
      medoroPaymentsActive,
      stripePaymentsActive,
      paymentsActive,
      medoroSettingsData,
      medoroGatewayKey,
      stripeSettingData,
      submitMedoroSettings,
      medoroSettingsForm,
      medoroSettingsFormValid,
      rsaKeyFileInputValidationRules,
      generatingRSAKeyPair,
      generatedPublicRSAKey,
      generatedPublicRSAKeyDialog,
      rsaPublicKeyModalClosed,
      submitStripeSettings,
      generateRSAKeys,
      icons,
      validators: { required }
    };
  },
};
</script>

<style scoped lang="scss">
.payment-settings-back-button-name {
  vertical-align: middle;
}
</style>
