import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import handleError from '@/plugins/handle-error';
import { useRouter } from '@core/utils';
import Vue from 'vue';
import { required } from '@core/utils/validation';

export default function useMedoroPaymentSettings() {
  const { router } = useRouter();
  const websiteId = router.currentRoute.params.websiteId;
  const medoroSettingsFormValid = ref(false);
  const medoroSettingsForm = ref(null);

  const validateMedoroSettingsForm = () => {
    medoroSettingsForm.value.validate();
  };

  const rsaKeyFileInputValidationRules = computed({
    get: () => {
      if (medoroSettingsData.value && medoroSettingsData.value.isRsaKeysValid) {
        return [];
      }

      return [required];
    }
  });

  const medoroSettingsData = ref({
    merchantId: null,
    autoDeposit: false,
    keyIndex: null,
    isRsaKeysValid: false,
  });

  const medoroGatewayKey = ref(null);

  const submitMedoroSettings = () => {
    if (medoroSettingsFormValid.value) {
      store.dispatch('websiteMedoroSettings/saveWebsiteMedoroSettings', {
        websiteId,
        settings: medoroSettingsData.value,
        gatewayKey: medoroGatewayKey.value,
      }).then((response) => {
        medoroSettingsData.value = response;
        Vue.notify({
          type: 'success',
          text: 'LPB settings updated',
        });
      }).catch((error) => {
        handleError(error);
      });
    } else {
      validateMedoroSettingsForm();
    }
  };

  store.dispatch('websiteMedoroSettings/fetchWebsiteMedoroSettingsByWebsiteId', websiteId)
    .then(medoroSettings => {
      if (medoroSettings) {
        medoroSettingsData.value = medoroSettings;
      }
    });

  return {
    medoroSettingsData,
    medoroGatewayKey,
    submitMedoroSettings,
    medoroSettingsForm,
    medoroSettingsFormValid,
    rsaKeyFileInputValidationRules,
  };
}
